"use client";
import { useEffect, useState, createContext, ReactNode } from "react";
import { ThemeProvider } from "next-themes";
import { User } from "firebase/auth";
import { auth } from "@/lib/firebase/client";
// undefined: onAuthStateChanged hasn't been called
// null: user is not signed in
// User: user signed in
interface ContextProps {
  user: User | null | undefined;
  userClaims: Record<string, any> | null | undefined;
  loading: boolean;
  error: string | null;
}

export const AuthContext = createContext<ContextProps>({
  user: undefined,
  userClaims: undefined,
  loading: true,
  error: null,
});

interface AuthProviderProps {
  children: ReactNode;
}

export function Providers({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [userClaims, setUserClaims] = useState<Record<string, any> | null>(
    null
  ); // State for user claims
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);

      if (currentUser) {
        // Fetch user claims if user is authenticated
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
          setUserClaims(idTokenResult.claims);
        } catch (error) {
          console.error("Error fetching user claims:", error);
          setError("Failed to load user claims.");
          setUserClaims(null);
        }
      } else {
        setUserClaims(null);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{ user, userClaims, loading, error }}>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        {children}
      </ThemeProvider>
    </AuthContext.Provider>
  );
}
